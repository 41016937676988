import permissions from '@/enums/permissions';

const monitoringProfileRouter = [
    {
        path: '/perfil-monitoramento/parametros',
        name: 'params-profile-monitoring',
        component: () => import('@/views/pages/ProfileMonitoring/Params/ProfileMonitoringParams.vue'),
        meta: {
            permissions: permissions.walletMonitoringParams,
            requiresAuth: true,
        },
    },
    {
        path: '/perfil-monitoramento/painel',
        name: 'params-profile-dashboard',
        component: () => import('@/views/pages/ProfileMonitoring/ProfileMonitoringDashboard.vue'),
        meta: {
            permissions: permissions.walletMonitoring,
            requiresAuth: true,
        },
    },
];

export default monitoringProfileRouter;
